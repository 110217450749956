$table-row-hover-background-color: #f1f1f1;
$cyan: #27aae1 !default;
$grey-darker: #172d36 !default;
$primary: $cyan !default;

@import '~/node_modules/bulma/bulma.sass';

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.is-danger {
  border-color: red;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.navbar a {
  text-decoration: underline;
}

.is-centered {
  text-align: center;
}

.heading{
  text-align: left;
  font-size: large;
  font-weight: bold;
}

.sub-heading{
  text-align: left;
  font-size: medium;
  font-weight: bold;
}

.clear {
  clear: both;
}

.scrollable-div {
  background-color: #f1f1f1;
  height: 300px;
  width: 400px;
  overflow-y: scroll;
  margin: 10px;
  text-align: justify;
  padding: 10px;
}
